
import { type Observable } from 'rxjs';
import { type User } from '@models/viewModels/user';

export abstract class UserGateway {
  /**
   * @feature get current user by mail
   */
  abstract getCurrentUser (mail: string): Observable<User>;
}
